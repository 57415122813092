/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// npm install bootstrap --save
import "bootstrap/dist/css/bootstrap-grid.min.css"
// Slick Slide
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// Import MainModule SCSS
import "./src/scss/style.scss"
import "./src/scss/fonts/font.scss"
